main {
	padding: 1rem 2rem 2rem 2rem;
	@include breakpoint(m) {
		padding: 1rem 4rem 2rem 4rem;
	}
	@include breakpoint(i) {
		padding: 1rem 2rem 2rem 3.1rem;
		display: grid;
		grid-template-columns: 25% 70%;
		grid-column-gap: 5%;
	}
	@include breakpoint(l) {
		padding: 1rem 1rem 2rem 0rem;
		max-width: 66rem;
		grid-template-columns: 22.875rem auto;
	}
}
