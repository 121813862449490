.School {
	margin-bottom: 1rem;
	margin-top: 0.5rem;
}

	.School__date {
		pointer-events: none;
		& > * {
			text-decoration: none;
			color: inherit;
		}
	}
