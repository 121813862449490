.Job {
	margin-bottom: 1rem;
	margin-top: 0.5rem;
}

	.Job__employer {
		float: left;
	}

	.Job__date {
		float: right;
		pointer-events: none;
		& > * {
			text-decoration: none;
			color: inherit;
		}
	}

	.Job__title {
		clear: both;
		font-style: italic;
	}
