// Clearfix
.group:after {
  content:"";
  display:block;
  clear:both;
}

/*------------------------------------*\
    #POSITIONING
\*------------------------------------*/

.wide { width: 100%; }
.rel { position: relative; }
.abs { position: absolute; }
.abs-center {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.pos-bottom { bottom: 0 }
.pos-top { top: 0 }
.pos-left { left: 0 }
.pos-right { right: 0 }

.list-reset {
    list-style: none;
    padding: 0;
    li { font-size: 1em; }
}

/*------------------------------------*\
    #ROUNDING
\*------------------------------------*/

.rounded { border-radius: $borderRadius; }

.square {
    border-radius: 0;
}

/*------------------------------------*\
    #TYPE
\*------------------------------------*/


.border-light { border-color: palette(white); }
.border-dashed { border-style: dashed; }

.text-light {
    font-weight:200;
}
.text-upper {
    text-transform: uppercase;
}
.text-italic {
    font-style: italic;
}
.text-underline {
    text-decoration: underline;
}
.text-smooth {
    -webkit-font-smoothing: antialiased;
}
.extrabold {
    font-weight: 800;
}

.text-body { font-family: $fontFamilyBody; }
.text-heading { font-family: $fontFamilyHeading; }
.text-fancy { font-family: $fontFamilyFancy; }
.text-hide {
    position: absolute;
    left: -100%;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

/*------------------------------------*\
    #VISIBILITY
\*------------------------------------*/

.no-js .show-for-js {
    display: none !important;
}

.js .hide-for-js {
    display: none !important;
}

@media print {
    .hide-for-print {
        display: none !important;
    }
}
