.js-toggleSection {
	position: relative;
	&:before, &:after {
		content: "";
		display: block;
		position: absolute;
		width: 1rem;
		height: .25rem;
		background-color: palette(black);
	}
	&:before {
		top: calc(50% - .125rem);
		right: 0;
	}
	&:after {
		transform-origin: center;
		transform: rotate(270deg);
		top: calc(50% - .125rem);
		right: 0;
		transition: transform .25s ease-in-out;
	}
	&.is-open:after {
		transform: rotate(0deg);
	}
	@include breakpoint(i) {
		&:before, &:after {
			display: none;
		}
	}
}

.js-section {
	max-height: 0;
	transition: max-height 0.5s;
	overflow: hidden;

	&.is-open {
		&.edu {
			max-height: 13rem;
		}
		&.skills {
			max-height: 26rem;
		}
		&.work {
			max-height: 82rem;
		}
		&.samples {
			max-height: 38rem;
		}
	}
	@include breakpoint(i) {
		max-height: none;
	}
}
