/*------------------------------------*\
    #BREAKPOINTS-GRID
\*------------------------------------*/

$breakpoints: (
    s: 0,
    m: 37em,
    i: 45em,
    l: 58em
);

$breakpointPrefixes: (\@s-,\@m-,\@l-);
$gridCols: 12;

/*------------------------------------*\
    #FEATURE-SWITCHES
\*------------------------------------*/

$includeNegativeMargins: false;
$negativeMarginLevels: 2;
$includePush: false;
$includePull: false;
$includeResponsiveVertCenter: false;
$includeResponsiveBorders: false;

/*------------------------------------*\
    #TYPE
\*------------------------------------*/

$fontFamilyHeading: 'PT Sans', sans-serif;
$fontFamilyBody: 'Arial', sans-serif;
$fontFamilyIcon: 'booster' !important;
$fontFamilyFancy: 'Dancing Script', cursive;

/*------------------------------------*\
    #UNITS
\*------------------------------------*/

$pushy-width: 260px;
$pad1: 8px;
$pad2: 16px;
$pad3: 32px;
$pad4: 48px;
$pad5: 84px;
$spacing: $pad1, $pad2, $pad3, $pad4, $pad5;
$borderRadius: 10px;
$fontSize6: 4.1em; 
$fontSize5: 3em; 
$fontSize4: 2.125em; 
$fontSize3: 1.75em;
$fontSize2: 1.2em; 
$fontSize1: 1em;
$fontSizeSmall: 0.875em;
$fontSizeSmaller: 0.75em;
$lineHeightSize6: 1.1;
$lineHeightSize5: 1.1;
$lineHeightSize4: 1.3;
$lineHeightSize3: 1.3;
$lineHeightSize2: 1.4;
$lineHeightSize1: 1.4;

/*------------------------------------*\
    #MISC
\*------------------------------------*/

$outerContainerWidth: 1700px;
$boxWidths: ('240','300','320','360','400','480','560','640','720','800','960');
$borderWidth: 1px;
