// Generate classes for each breakpoint, defaults defined below
@mixin responsiveClassesGenerator(
    $gridColumns: 12,                    // number of grid columns 
    $breakpoints: (0, 480px, 800px),     // array of breakpoints
    $breakpointPrefixes: (s-,m-,l-),     // array of breakpoint prefixes
    $breakpointType: 'px'                // breakpoint type px or em 
) {

    // Top-level grid classes, no prefix
    @include gridClasses('', $gridColumns);

    // Top-level classes first, no prefix
    @include utilityClasses();

    // Now the breakpoints
    @for $i from 1 through length($breakpointPrefixes) {
        $prefix: nth($breakpointPrefixes, $i);
        $breakpoint_min: nth($breakpoints, $i);

        // Add one to support pixel breakpoints, EMs don't need the extra bump
        @if $breakpointType == 'px' {
            $breakpoint_min: nth($breakpoints, $i) + 1;
        }

        $breakpoint_max: '';

        @if $i < length($breakpointPrefixes) {

            // Adjust max breakpoint to avoid overlap
            @if $breakpointType == 'px' {
                $breakpoint_max: nth($breakpoints, $i+1) - 1;
            } @else {
                $breakpoint_max: nth($breakpoints, $i+1) - (1/16); // 1/16 of an em should be 1px
            }
        }

        // Smallest breakpoint
        @if $breakpoint_min == 1 {
            @media (max-width: $breakpoint_max) {
                @include gridClasses($prefix, $gridColumns);
                @include utilityClasses($prefix);
            }
        } @else {
            // Middle breakpoints
            @if $breakpoint_max != '' {
                @media (min-width: $breakpoint_min) and (max-width: $breakpoint_max) {
                    @include gridClasses($prefix, $gridColumns);
                    @include utilityClasses($prefix);
                }

            // Largest breakpoint
            } @else {
                @media (min-width: $breakpoint_min) {
                    @include gridClasses($prefix, $gridColumns);
                    @include utilityClasses($prefix);
                }
            }
        }
    }
}
