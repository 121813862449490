@charset "UTF-8";

@font-face {
    font-family: "booster";
    src:url("/fonts/booster.eot");
    src:url("/fonts/booster.eot?#iefix") format("embedded-opentype"),
        url("/fonts/booster.woff") format("woff"),
        url("/fonts/booster.ttf") format("truetype"),
        url("/fonts/booster.svg#booster") format("svg");
    font-weight: normal;
    font-style: normal;
}

%icon {
    font-family: $fontFamilyIcon;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    position: relative;
    text-transform: none !important;
    top: 0.2em;
    left: 0.02em;
    margin-right: 0.2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 
}

[class^="svg-icon-"], [class*=" svg-icon-"] {
    height: 1em;
    width: 1em;
    display: inline-block;
    fill: currentColor;
    vertical-align: middle;
}

[data-icon]:before {
    @extend %icon;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    @extend %icon;
}

.icon\+lg {
    font-size: 1.2em;
}

// Paste icon classes
.icon-close:before {
  content: "\61";
}
.icon-telephone:before {
  content: "\62";
}
.icon-facebook:before {
  content: "\63";
}
.icon-twitter:before {
  content: "\64";
}
