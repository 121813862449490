.EduSection {
    @include breakpoint(i) {
        text-align: right;
    }
}

.SkillsSection {
    @include breakpoint(i) {
        text-align: right;
        grid-column: 1;
        grid-row: 2 / 5;
    }
}

.SamplesSection {
    @include breakpoint(i) {
        grid-column: 2;
        grid-row: 1 / 3;
    }
}

.WorkSection {
    @include breakpoint(i) {
        grid-column: 2;
        grid-row: 3 / 8;
    }
}

.ExtrasSection {
    @include breakpoint(i) {
        text-align: right;
        grid-column: 1;
        grid-row: 5;
    }
}