/*------------------------------------*\
    #COLORS
\*------------------------------------*/

/**
 * Use our palette map to create color classes. 
 *
 * 1. Generate the proper class name. If we're dealing with the 'base' tone, just use 
 *    the color name for the class, e.g. 'green' and not 'green-base'
 *
 * 2. We want our color classes to override :visited colors
 */
@each $color, $toneMap in $palettes {
    @each $tone, $value in $toneMap {
        @if (#{$tone} == 'base') { // [1]
            .#{$color}, a.#{$color} { // [2]
                color: map-get(map-get($palettes, $color), #{$tone});
            }
            .bg-#{$color} {
                background-color: map-get(map-get($palettes, $color), #{$tone});
            }
            // Leaving for reference to see how to apply opacity
            // .bg-#{$color}-50 {
            //     background: rgba(map-get(map-get($palettes, $color), #{$tone}), 0.5);
            // }
        } @else {
            .#{$color}-#{$tone}, a.#{$color}-#{$tone} { // [2]
                color: map-get(map-get($palettes, $color), #{$tone});
            }
            .bg-#{$color}-#{$tone} {
                background-color: map-get(map-get($palettes, $color), #{$tone});
            }
        }
    }
}
